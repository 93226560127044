import React from 'react';
import printJS from 'print-js';
import PrintIcon from '@material-ui/icons/Print';
import Tooltip from '@material-ui/core/Tooltip';
import ScPDF from "./ScPDF";
import HcpPDF from "./HcpPDF";
import FavsPDF from "./FavsPDF";
import PlanPDF from "./PlanPDF";

const PrintTreatments = ({ type, passHCP, passSC, passFavs, passPlan }) => {

  let lang = localStorage.getItem("language");

  const printList = () => {
    printJS({
      printable: type,
      type: "html",
      targetStyles: ['*'],
      maxWidth: "1300",
      documentTitle: lang === "English" ? "Treatments" : "Traitments"
    })
  }

  return (
    <React.Fragment>
      <Tooltip title="Download treatments details">
        <PrintIcon className="material-icons print-icon"
          onClick={() => { printList() }}>
        </PrintIcon>
      </Tooltip>

      <div className="hidden-print-container">
        <div className="hidden-print">
          <div className="treatments-pdf">
            <div className="padding-class" id={type}>
              {passSC && <ScPDF passSC={passSC} />}
              {passHCP && <HcpPDF passHCP={passHCP} />}
              {passFavs && <FavsPDF passFavs={passFavs} />}
              {passPlan && <PlanPDF passPlan={passPlan} />}
            </div>
          </div>
        </div>
      </div>

    </React.Fragment>
  )

}

export default PrintTreatments
