import ReactGA from "react-ga4";

export const initGA = (trackingID) => {
    ReactGA.initialize(trackingID, {
        debug: true,
        gaOptions: {
            cookieDomain: "none",
        },
    });
}

export const PageView = () => {
    ReactGA.send("pageview");
}

export const Event = (category, action, label) => {
    ReactGA.event({
        category: category,
        action: action,
        label: label,
        value: 1
    });
}; 