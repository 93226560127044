import React, {useState} from 'react'
import { withRouter, useHistory } from 'react-router-dom'
import { useForm } from "react-hook-form";
import * as Yup from 'yup';
import {Formik, Field, Form, ErrorMessage} from 'formik';

import Footer from '../../components/Footer';
import NavigationButton from "../../components/UI/Buttons/NavigationButton";
import './RecoveryCode.css';
import TranslateButton from "../../components/UI/TranslateButton";

const RecoveryCode = ({history}) => {

  //Get value of language from local storage
  let lang = localStorage.getItem("language")

  const handleButtonNav = () => {
    history.push(`/home`)
  }
  return(
    <div className="reset-page">
      <div className="title-section">
        <div>
          <div className="login-translation"> <TranslateButton/> </div>
        </div>
        <h1>JIA Option Map</h1>
      </div>

      <div className="form-section">
        <h2>{lang === "English" ? "Reset Link Email Sent" : "Récupération du mot de passe"}</h2>
            <NavigationButton 
              title={lang === "English" ? 
              "We sent a Password Reset link to the email if it is associated with your account" : 
              "Nous enverrons un code de vérification à l'adresse électronique si elle est associée à votre compte"}
              btnText={lang === "English" ? "Login" : "Connexion"}
              handleNavigation={handleButtonNav}
            />
      </div>
      <Footer />
    </div>
  )
}

export default  withRouter(RecoveryCode)