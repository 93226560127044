import React from 'react'
import { Button } from "reactstrap";

const NavigationButton = ({ title, btnText, handleNavigation, btnText_2, handleNavigation_2 }) => {
  return (
    <div className="next-btn-container">
      <p>{title}</p>
      <Button className="next-btn" onClick={handleNavigation}>{btnText}</Button>
      {btnText_2 ? <Button className="next-btn add-btn" onClick={handleNavigation_2}>{btnText_2}</Button> : ''}
    </div>
  )
}

export default NavigationButton