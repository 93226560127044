import React, {useState, useEffect} from 'react'
import {Dialog, DialogContent, DialogTitle, Radio, RadioGroup, FormControlLabel, Switch} from '@material-ui/core'

import {useForm, Controller} from "react-hook-form"

import './Forms.css'
import {Formik, Form} from 'formik';
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";

const UserForm = ({open, setOpenForm, handleSubmitForm, mode, values}) => {
  const {register, handleSubmit, reset, errors, control} = useForm();
  const [enabled, setEnabled] = useState(true)
  console.log(handleSubmitForm);
  const [visibilityToggle, setVisibilityToggle] = useState(false);
  // const [enabled, setEnabled] = useState(true)

  // Call the passed prop handleSubmit from @see UsersList if all fields are valid
  const onSubmit = data => {
    if(true) {
    handleSubmitForm(data)
    } else {
      // let {password, ...rest} = data
      //data.password = ''
      handleSubmitForm(data)
    }
  }

  // Close the form dialog window
  const handleClosing = () => {
    setOpenForm(false)
  }

  // const handlePassToggle = () => {
  //   setEnabled(!enabled)
  // }

  {/*}
  const generateUserID = (length, chars) => {
    let userID = ''
    while(passwordTester(userID) == false){
      for (let i = length; i > 0; --i) userID += chars[Math.floor(Math.random() * chars.length)];
    }    
    return userID;
  }

  {/*}
  useEffect(() => {
    setEnabled(true)
    // if(mode === "Edit") setEnabled(false)
    if(values && mode === 'Add') values.username = generateUserID(8, '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ')
    reset(values)
  }, [values])
*/}
  return (
    <Dialog fullWidth={true} open={open}>
      <DialogTitle className="form-dialog-title">{mode} User</DialogTitle>
      <DialogContent>
      <Formik>
        <Form className='form-container' onSubmit={handleSubmit(onSubmit)} component="form">
          <div className="form-row">
            <div className="form-field-container fill-width">
              <label>Username</label>
              <input
                name="username"
                ref={register({ required: true })}
                pattern="^([A-Za-z0-9]*$)(?!.*\s)"
                minLength="4"           
              />
              {errors.username && <p>This field is required</p>}
            </div>
          </div>
          <div className="form-row">
            <div className="form-field-container fill-width">
              <label>Email</label>
              <input
                name="email"
                ref={register({ required: true })}
              />
              {errors.email && <p>This field is required</p>}
            </div>
          </div>

          {/* {mode === 'Add' && */}
          {/*
          <div className="form-row">
            <div className="form-field-container fill-width">
              <div className="update-pass">
                <label>Password</label>
                {/* {mode === 'Edit' && <Switch
                  checked={enabled}
                  onChange={handlePassToggle}
                  inputProps={{ 'aria-label': 'secondary checkbox' }}
                />  *
              </div>
              <div className='iconInput'>
                <input
                  className='field'
                  name="password"
                  type="text"
                  ref={register({ required: true })}
                  pattern="^(?=.*[A-Z])(?=.*[0-9])[A-Z0-9]+$"
                  minLength="8"
                />
                {errors.password && <p>This field is required</p>}
              </>
              }
              {!enabled && 
              <>
                <input
                  disabled
                  name="password"
                  type="password"
                  value="*********"
                  ref={register()}
                />
              </>
              } 
            </div>
          </div>
          */}
          
          <div className="form-row">
            <div className="form-field-container">
              <label>Type</label>
              <Controller
                as={
                  <RadioGroup row aria-label="type">
                    <FormControlLabel 
                      className="radio-space" 
                      value="user" 
                      control={<Radio className="c"/>} 
                      labelPlacement="end"
                      label="User"
                    />
                    <FormControlLabel 
                      className="radio-space" 
                      value="admin" 
                      control={<Radio/>} 
                      labelPlacement="end" 
                      label="Admin" 
                    />
                  </RadioGroup>
                }
                name="type" 
                control={control}
                rules={{ required: true }}
                defaultValue="" 
              />
              {errors.type && <p>This field is required</p>}
            </div>
          </div>

          <div className="form-buttons">
            <input type="submit" />
            <button type="button" onClick={handleClosing}>Cancel</button>
          </div>          
        </Form>
      </ Formik>
      </DialogContent>
    </Dialog>
  )
}

export default UserForm