import React from 'react'
import { Redirect, Route, useLocation } from 'react-router-dom'
//import { type, user, getCurrentUser, getUserType } from '../AuthenticationService'
import { valid, email, validateLink } from '../PasswordResetService'

const PrivateRoute = ({ component: Component, ...rest }) => {
  const location = useLocation()
  const queryParameters = new URLSearchParams(location.search)
  const [isLoading, setIsLoading] = React.useState(true)

  const getData = async(token) => {
    if(!email) await validateLink(token)
    setIsLoading(false)
  }

  React.useEffect(() => {
    const token = {
        passwordResetToken: queryParameters.get("token")
    }
    getData(token)
  },[])

  return (
    <>
      {isLoading
        ? <></>
        : 
        <Route
          {...rest}
          render={props =>
            email ? (
              <Component {...props} />
            ) : (
                /* Instead of reset path create error page*/
              <Redirect to={{ pathname: '/reset', state: { from: props.location } }} />
            )
          }
        />
      }
    </>
  )
}

export default PrivateRoute