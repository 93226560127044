import React, { useState, useEffect } from 'react'
import TopBar from '../../../components/Admin/TopBar'
import Header from "../../../components/Home/Header";
import UserNav from '../../../components/UserDash/UserNav'
//import {PieChart, Pie, Legend} from 'recharts'
import { BarChart, CartesianGrid, XAxis, YAxis, Cell, Tooltip, Bar, ResponsiveContainer } from 'recharts'
import { getRequest } from "../../../API/ApiHandler"

import '../UserPanel.css'
import IdleComponent from '../../../components/IdleComponent'

//Get value of language from local storage
let lang = localStorage.getItem("language")
var fontLimit = 17;
var usedElements = 1;
var medElements = 1;
var plannedElements = 1;

const TreatmentAvg = (props) => {
  const [user, setUser] = useState()
  const [logs, setLogs] = useState([])
  const [rawUsedLogs, setRawUsedLogs] = useState([])
  const [rawPlannedLogs, setRawPlannedLogs] = useState([])
  const [rawMedLogs, setRawMedLogs] = useState([])

  const [date1Used, setDate1Used] = useState('default')
  const [date2Used, setDate2Used] = useState('default')

  const [date1Med, setDate1Med] = useState('default');
  const [date2Med, setDate2Med] = useState('default');

  const [date1Planned, setDate1Planned] = useState('default');
  const [date2Planned, setDate2Planned] = useState('default');

  const [usedDates, setUsedDates] = useState([])
  const [usedExport, setUsedExport] = useState([])
  const [usedLimited, setUsedLimited] = useState([])

  const [medDates, setMedDates] = useState([])
  const [medExport, setMedExport] = useState([])
  const [medLimited, setMedLimited] = useState([])

  const [plannedDates, setPlannedDates] = useState([])
  const [plannedExport, setPlannedExport] = useState([])
  const [plannedLimit, setPlannedLimit] = useState([])

  const [isLoading, setIsLoading] = useState(true)
  const [navTo, setNavTo] = useState('')

  const handleStepperNav = to => {
    props.history.push(to)
  }

  const getUser = async () => {
    let response = await getRequest('/auth/users/current/me')
    let user = ''
    if (response) {
      user = response.data.id
      setUser(user)
      getLogs(user)
    }
  }

  const getLogs = async (user) => {
    const data = await getRequest(`/logs/${user}`)
    if (data) {
      data.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
      let updatedLog = data.map(log => {
        const options = { timeStyle: 'short', timeZone: 'UTC' }
        const time = new Date(log.createdAt).toLocaleTimeString('en-CA', options)
        const date = new Date(log.createdAt).toLocaleDateString('en-CA')
        log.date = date
        log.time = time
        return log
      })
      setLogs(updatedLog)
      getUsed(updatedLog)
      getPlanned(updatedLog)
      getMed(updatedLog)
    }
    setIsLoading(false)
  }

  const getUsed = (updatedLog) => {
    const exportList = updatedLog.map(el => {
      if (el.step_one !== null) {
        let log = {
          "used": el.step_one ? JSON.parse(el.step_one).other_treatments : '',
          "date": el.date
        }
        return log
      }
    })
    setUsedExport(exportList)
    removeNullUsed(exportList)
  }

  const getMed = (updatedLog) => {
    const exportList = updatedLog.map(el => {
      if (el.step_one !== null) {
        let log = {
          "med": el.step_one ? JSON.parse(el.step_one).selected_meds : '',
          "date": el.date
        }
        return log
      }
    })
    setMedExport(exportList)
    removeNullMed(exportList)
  }

  const getPlanned = (updatedLog) => {
    const exportList = updatedLog.map(el => {
      if (el.plan !== null) {
        let log = {
          "planned": el.plan ? el.plan : '',
          "date": el.date
        }
        return log
      }
    })
    setPlannedExport(exportList)
    removeNullPlanned(exportList)
  }

  const removeNullUsed = (exportList) => {
    exportList = exportList.filter(function (element) {
      return element !== undefined
    })
    setUsedExport(exportList)
    setRawUsedLogs(exportList)
    getDatesUsed(exportList)
    usedCount(exportList)
    return exportList
  }

  const getDatesUsed = (exportList) => {
    let dates = [];
    exportList.forEach((el) => {
      dates = [el.date, ...dates]
    });
    dates = [...new Set(dates)]
    setUsedDates([...dates])
  }

  const removeNullMed = (exportList) => {
    exportList = exportList.filter(function (element) {
      return element !== undefined
    })
    setMedExport(exportList)
    setRawMedLogs(exportList)
    getDatesMed(exportList)
    medCount(exportList)
    return exportList
  }

  const getDatesMed = (exportList) => {
    let dates = [];
    exportList.forEach((el) => {
      dates = [el.date, ...dates]
    });
    dates = [...new Set(dates)]
    setMedDates([...dates])
  }

  const removeNullPlanned = (exportList) => {
    exportList = exportList.filter(function (element) {
      return element !== undefined
    })
    setPlannedExport(exportList)
    setRawPlannedLogs(exportList)
    planCount(exportList)
    getPlannedDates(exportList)
    return exportList
  }

  const getPlannedDates = (exportList) => {
    let dates = []
    exportList.forEach((el) => {
      dates = [el.date, ...dates]
    });
    dates = [...new Set(dates)]
    setPlannedDates(dates)
  }

  const planCount = (list) => {
    let counts = []
    for (var i = 0; i < list.length; i++) {
      for (var j = 0; j < JSON.parse(list[i].planned).length; j++) {
        if (JSON.parse(list[i].planned)[j] !== null) {
          counts.push(JSON.parse(list[i].planned)[j])
        }
      }
    }

    var en_output = Object.values(counts.reduce((obj, { name }) => {

      if (obj[name] === undefined) {
        obj[name] = { name: name, occurrences: 1 };
      } else {
        obj[name].occurrences++;
      }

      return obj;

    }, {}));

    var fr_output = Object.values(counts.reduce((obj, { fr_name }) => {

      if (obj[fr_name] === undefined) {
        obj[fr_name] = { name: fr_name, occurrences: 1 };
      } else {
        obj[fr_name].occurrences++;
      }

      return obj;

    }, {}));

    var output = lang === "English" ? en_output : fr_output

    setPlannedExport(output)
    setPlannedLimit(output)
    return output
  }

  const usedCount = (list) => {
    let counts = []
    for (var i = 0; i < list.length; i++) {
      for (var j = 0; j < list[i].used.length; j++) {
        if (list[i].used.length !== 0) {
          counts.push(list[i].used[j])
        }
      }
    }
    var en_output = Object.values(counts.reduce((obj, { name }) => {

      if (obj[name] === undefined) {
        obj[name] = { name: name, occurrences: 1 };
      } else {
        obj[name].occurrences++;
      }

      return obj;

    }, {}));

    var fr_output = Object.values(counts.reduce((obj, { fr_name }) => {

      if (obj[fr_name] === undefined) {
        obj[fr_name] = { name: fr_name, occurrences: 1 };
      } else {
        obj[fr_name].occurrences++;
      }

      return obj;

    }, {}));

    var output = lang === "English" ? en_output : fr_output

    setUsedExport(output)
    setUsedLimited(output)
    return output
  }

  const medCount = (list) => {
    let counts = []
    for (var i = 0; i < list.length; i++) {
      for (var j = 0; j < list[i].med.length; j++) {
        if (list[i].med.length !== 0) {
          counts.push(list[i].med[j])
        }
      }
    }
    var en_output = Object.values(counts.reduce((obj, { name }) => {

      if (obj[name] === undefined) {
        obj[name] = { name: name, occurrences: 1 };
      } else {
        obj[name].occurrences++;
      }

      return obj;

    }, {}));

    var fr_output = Object.values(counts.reduce((obj, { fr_name }) => {

      if (obj[fr_name] === undefined) {
        obj[fr_name] = { name: fr_name, occurrences: 1 };
      } else {
        obj[fr_name].occurrences++;
      }

      return obj;

    }, {}));

    var output = lang === "English" ? en_output : fr_output

    setMedExport(output)
    setMedLimited(output)
    return output
  }

  const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      return (
        <div className="treatmentAvg-custom-tooltip">
          <p>{`${lang === "English" ? "Treatment" : "Traitement"} : ${payload[0].payload.name}`}</p>
          <p>{`${lang === "English" ? "Number" : "Numéro"} : ${payload[0].payload.occurrences}`}</p>
        </div>
      )
    }
    return null;
  }

  const CustomTooltip2 = ({ active, payload }) => {
    if (active && payload && payload.length) {
      return (
        <div className="treatmentAvg2-custom-tooltip">
          <p>{`${lang === "English" ? "Treatment" : "Traitement"} : ${payload[0].payload.name}`}</p>
          <p>{`${lang === "English" ? "Number" : "Numéro"}: ${payload[0].payload.occurrences}`}</p>
        </div>
      )
    }
    return null;
  }

  const CustomTooltip3 = ({ active, payload }) => {
    if (active && payload && payload.length) {
      return (
        <div className="treatmentAvg3-custom-tooltip">
          <p>{`${lang === "English" ? "Treatment" : "Traitement"} : ${payload[0].payload.name}`}</p>
          <p>{`${lang === "English" ? "Number" : "Numéro"}: ${payload[0].payload.occurrences}`}</p>
        </div>
      )
    }
    return null;
  }

  useEffect(() => {
    setIsLoading(true)
    getUser()
    if (user) getLogs()
  }, [user])

  const handleDate1Used = (event) => {
    setDate1Used(event.target.value);
  }

  const handleDate2Used = (event) => {
    setDate2Used(event.target.value);
  }

  useEffect(() => {
    if (date1Used === "default") {
      setDate2Used("default");
    } else {
      if (date2Used === "default") {
        setDate2Used(usedDates[usedDates.length - 1]);
      } else {
        onClickUsed(date1Used, date2Used, rawUsedLogs)
      }
    }
  }, [date1Used]);

  useEffect(() => {
    if (date2Used === "default") {
      setUsedLimited(usedExport);
    } else {
      onClickUsed(date1Used, date2Used, rawUsedLogs);
    }
  }, [date2Used]);

  const onClickUsed = (date1, date2, logs) => {
    if (date1 === "default" || date2 === "default") {
      return;
    }
    let dateRange = []
    var d1 = Date.parse(date1)
    var d2 = Date.parse(date2)
    logs.map(el => {
      var logDate = Date.parse(el.date)
      if (d1 <= logDate && logDate <= d2) {
        dateRange.push(el)
      } else if (d2 <= logDate && logDate <= d1) {
        dateRange.push(el)
      }
    })

    let counts = []
    for (var i = 0; i < dateRange.length; i++) {
      for (var j = 0; j < dateRange[i].used.length; j++) {
        if (dateRange[i].used.length !== 0) {
          counts.push(dateRange[i].used[j])
        }
      }
    }

    var en_output = Object.values(counts.reduce((obj, { name }) => {

      if (obj[name] === undefined) {
        obj[name] = { name: name, occurrences: 1 };
      } else {
        obj[name].occurrences++;
      }

      return obj;

    }, {}));

    var fr_output = Object.values(counts.reduce((obj, { fr_name }) => {

      if (obj[fr_name] === undefined) {
        obj[fr_name] = { name: fr_name, occurrences: 1 };
      } else {
        obj[fr_name].occurrences++;
      }

      return obj;

    }, {}));

    var output = lang === "English" ? en_output : fr_output
    setUsedLimited(output)
  }


  const handleDate1Med = (event) => {
    setDate1Med(event.target.value);
  }

  const handleDate2Med = (event) => {
    setDate2Med(event.target.value);
  }

  useEffect(() => {
    if (date1Med === "default") {
      setDate2Med("default");
    } else {
      if (date2Med === "default") {
        setDate2Med(medDates[medDates.length - 1]);
      } else {
        onClickMed(date1Med, date2Med, rawMedLogs)
      }
    }
  }, [date1Med]);

  useEffect(() => {
    if (date2Med === "default") {
      setMedLimited(medExport);
    } else {
      onClickMed(date1Med, date2Med, rawMedLogs);
    }
  }, [date2Used]);

  const onClickMed = (date1, date2, logs) => {
    if (date1 === "default" || date2 === "default") {
      return;
    }
    let dateRange = []
    var d1 = Date.parse(date1)
    var d2 = Date.parse(date2)
    logs.map(el => {
      var logDate = Date.parse(el.date)
      if (d1 <= logDate && logDate <= d2) {
        dateRange.push(el)
      } else if (d2 <= logDate && logDate <= d1) {
        dateRange.push(el)
      }
    })

    let counts = []
    for (var i = 0; i < dateRange.length; i++) {
      for (var j = 0; j < dateRange[i].med.length; j++) {
        if (dateRange[i].med.length !== 0) {
          counts.push(dateRange[i].med[j])
        }
      }
    }

    var en_output = Object.values(counts.reduce((obj, { name }) => {

      if (obj[name] === undefined) {
        obj[name] = { name: name, occurrences: 1 };
      } else {
        obj[name].occurrences++;
      }

      return obj;

    }, {}));

    var fr_output = Object.values(counts.reduce((obj, { fr_name }) => {

      if (obj[fr_name] === undefined) {
        obj[fr_name] = { name: fr_name, occurrences: 1 };
      } else {
        obj[fr_name].occurrences++;
      }

      return obj;

    }, {}));

    var output = lang === "English" ? en_output : fr_output
    setMedLimited(output)
  }


  const handleDate1Planned = (event) => {
    setDate1Planned(event.target.value)
  }

  const handleDate2Planned = (event) => {
    setDate2Planned(event.target.value)
  }

  useEffect(() => {
    if (date1Planned === "default") {
      setDate2Planned("default");
    } else {
      if (date2Planned === "default") {
        setDate2Planned(plannedDates[plannedDates.length - 1]);
      } else {
        onClickPlanned(date1Planned, date2Planned, rawPlannedLogs)
      }
    }
  }, [date1Planned])

  useEffect(() => {
    if (date2Planned === "default") {
      setPlannedLimit(plannedExport);
    } else {
      onClickPlanned(date1Planned, date2Planned, rawPlannedLogs);
    }
  }, [date2Planned]);

  const onClickPlanned = (date1, date2, logs) => {
    if (date1 === "default" || date2 === "default") {
      return;
    }
    let dateRange = []
    var d1 = Date.parse(date1)
    var d2 = Date.parse(date2)
    logs.map(el => {
      var logDate = Date.parse(el.date)
      if (d1 <= logDate && logDate <= d2) {
        dateRange.push(el)
      } else if (d2 <= logDate && logDate <= d1) {
        dateRange.push(el)
      }
    })

    let counts = []
    for (var i = 0; i < dateRange.length; i++) {
      for (var j = 0; j < JSON.parse(dateRange[i].planned).length; j++) {
        if (JSON.parse(dateRange[i].planned)[j] !== null) {
          counts.push(JSON.parse(dateRange[i].planned)[j])
        }
      }
    }
    var output = Object.values(counts.reduce((obj, { name }) => {
      if (obj[name] === undefined)
        obj[name] = { name: name, occurrences: 1 };
      else
        obj[name].occurrences++;
      return obj;
    }, {}));
    setPlannedLimit(output)
  }


  const colorList = ["#ff0000", "#ffa500", "#ffff00", "#00ff00", "#00ffff", "#0000ff", "#800080",]

  function colorFill(index, parts){
    if(parts>0){
    const value = Math.floor(255/parts)*65536
    var result = (255 - Math.floor(255/parts)*(index + 1) + value*(index + 1)).toString(16)

    while(result.length < 6){
      result = "0" + result
    }

    return "#" + result
  }else{return "#0000ff"}
  }

  function CustomizedTick(props) {
    const { x, y, stroke, payload } = props;
    const infor1 = payload.value.toString().replace("(","  (")
    const infor2 = infor1.replace("   (","  (")
    const infor3 = infor2.replace("/","/ ")
    const infor4 = infor3.replace("-","- ")
    const infor5 = infor4.replace("Hydroxychloroquine","Hydroxy- chloroquine")

    const tspans = infor5.split(" ")
      .map((value, i) => <tspan style={{ fontWeight: "bold" }} fontSize= {22-fontLimit} scaleToFit="true" textAnchor="middle" x="0" y={(i * 15).toString()} dy="15">{value}</tspan>);

    return (
      <g transform={`translate(${x},${y})`}>
        <text x={0} y={0} dy={16} fill="#666">
          {tspans}
        </text>
      </g>
    );
  }

  return (
    <>
      <IdleComponent history={props.history} />
      <div className="user-container">
        <div className='page-header'>
          <Header current={props} handleNav={handleStepperNav} setNavTo={setNavTo} />
        </div>
        <div className='user-nav'>
          <UserNav />
        </div>
        <div className="user-body">
          <div className="captions">
            <h3><b>{lang === "English" ? "Planned and used treatments" : "Traitements prévus et utilisés"}</b></h3>
            <p>{lang === "English" ? "Here you can review how many times you have added treatments to your plan and used certain treatments." :
              "Ici vous pouvez voir combien de fois vous avez ajouté des traitements à votre plan et utilisé certains traitements."}</p>
            <p>{lang === "English" ? "The first graph shows how many times you have added a treatment to your plan using the JIA Option Map." :
              "Le premier graphique montre combien de fois vous avez ajouté un traitement à votre plan à l'aide de la Carte d’options en AJI."}</p>
            <p>{lang === "English" ? "The second and the third graphs show how many times you've used the treatments you planned." :
              "Le deuxième graphique montre combien de fois vous avez utilisé les traitements que vous aviez planifiés."}</p>
            <p>{lang === "English" ? "This will let you know which treatments you have used the most!" :
              "Cela vous permettra de savoir quels traitements vous avez utilisés le plus!"}</p>
            <br></br>
          </div>

          <div className={"chartWrapper"}>

            <div style ={{visibility:"hidden"}}>{fontLimit = Math.max(plannedLimit.length, usedLimited.length, medLimited.length)}</div>
            <div style ={{visibility:"hidden"}}>{usedElements = usedLimited.length}</div>
            <div style ={{visibility:"hidden"}}>{medElements = medLimited.length}</div>
            <div style ={{visibility:"hidden"}}>{plannedElements = plannedLimit.length}</div>

            <div className="t3">
              <p><b>{lang === "English" ? "Treatments Planned" : "Traitements prévus"}</b></p>
            </div>

            <div className={"intervalDropdown"}>
              <div className={lang === "English" ? "chooseTimes1_en" : "chooseTimes1_fr"}>
                <select disabled={isLoading} onChange={handleDate1Planned} className="select_date">
                  <option value='default'>{lang === "English" ? "-- Select a Date --" : "-- Sélectionnez une date --"}</option>
                  {plannedDates !== undefined && plannedDates.length !== 0 && plannedDates.map((list) => <option key={list} value={list} selected={list === date1Planned}>{list}</option>)}
                </select>
              </div>

              <div className={lang === "English" ? "chooseTimes2_en" : "chooseTimes2_fr"}>
                <select disabled={isLoading} onChange={handleDate2Planned} className="select_date">
                  <option value='default'>{lang === "English" ? "-- Select a Date --" : "-- Sélectionnez une date --"}</option>
                  {plannedDates !== undefined && plannedDates.length !== 0 && plannedDates.map((list) => <option key={list} value={list} selected={list === date2Planned}>{list}</option>)}
                </select>
              </div>
            </div>

            <ResponsiveContainer width="95%" height={400}>
              <BarChart className="bar" data={plannedLimit} margin={{ top: 5, right: 30, left: 30, bottom: 150 }}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis width={20} tick={<CustomizedTick />} dataKey="name" interval={0} label={{ value: lang === "English" ? 'Treatment' : 'Traitement', position: "bottom", offset: 120 }} />
                <YAxis dataKey="occurrences" allowDecimals={false} label={{ value: lang === "English" ? "Times Used" : "Traitements utilisés", position: "insideLeft", angle: -90 }} />
                <Tooltip content={<CustomTooltip />} />
                <Bar dataKey="occurrences" fill="#1f417e" maxBarSize={50}>
                  {
                    plannedLimit.map((entry, index) => (
                      <Cell fill={colorFill(index, plannedElements)} />
                    ))
                  }
                </Bar>
              </BarChart>
            </ResponsiveContainer>

          </div>


          <div className={"chartWrapper"}>
            <hr></hr><div className="t4">
              <p><b>{lang === "English" ? "Treatments Used" : "Traitements utilisés"}</b></p>
            </div>

            <hr></hr><div className="t4">
              <p><b>{lang === "English" ? "1. Prescribed Arthritis Medication" : "1. Médicaments contre l'arthrite prescrits"}</b></p>
            </div>

            <div className={"intervalDropdown"}>
              <div className={lang === "English" ? "chooseTimes1_en" : "chooseTimes1_fr"}>
                <select disabled={isLoading} onChange={handleDate1Med} className="select_date">
                  <option value='default'>{lang === "English" ? "-- Select a Date --" : "-- Sélectionnez une date --"}</option>
                  {medDates !== undefined && medDates.length !== 0 && medDates.map((list) => <option key={list} value={list} selected={list === date1Med}>{list}</option>)}
                </select>
              </div>

              <div className={lang === "English" ? "chooseTimes2_en" : "chooseTimes2_fr"}>
                <select disabled={isLoading} onChange={handleDate2Med} className="select_date">
                  <option value='default'>{lang === "English" ? "-- Select a Date --" : "-- Sélectionnez une date --"}</option>
                  {medDates !== undefined && medDates.length !== 0 && medDates.map((list) => <option key={list} value={list} selected={list === date2Med}>{list}</option>)}
                </select>
              </div>
            </div>

            <ResponsiveContainer width="95%" height={400}>
              <BarChart className="bar" data={medLimited} margin={{ top: 5, right: 30, left: 30, bottom: 160 }}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis width={20} tick={<CustomizedTick />} dataKey="name" interval={0} label={{ value: lang === "English" ? 'Treatment' : 'Traitement', position: "bottom", offset: 130 }} />
                <YAxis dataKey="occurrences" allowDecimals={false} label={{ value: lang === "English" ? "Times Used" : "Traitements utilisés", position: "insideLeft", angle: -90 }} />
                <Tooltip content={<CustomTooltip2 />} />
                <Bar dataKey="occurrences" fill="#1f417e" maxBarSize={50}>
                  {
                    medLimited.map((entry, index) => (
                      console.log(colorFill(index, medElements)),
                      <Cell fill={colorFill(index, medElements)} />
                    ))
                  }
                </Bar>
              </BarChart>
            </ResponsiveContainer>


            <hr></hr><div className="t4">
              <p><b>{lang === "English" ? "2. Other Treatments" : "2. Autres Traitements"}</b></p>
            </div>

            <div className={"intervalDropdown"}>
              <div className={lang === "English" ? "chooseTimes1_en" : "chooseTimes1_fr"}>
                <select disabled={isLoading} onChange={handleDate1Used} className="select_date">
                  <option value='default'>{lang === "English" ? "-- Select a Date --" : "-- Sélectionnez une date --"}</option>
                  {usedDates !== undefined && usedDates.length !== 0 && usedDates.map((list) => <option key={list} value={list} selected={list === date1Used}>{list}</option>)}
                </select>
              </div>

              <div className={lang === "English" ? "chooseTimes2_en" : "chooseTimes2_fr"}>
                <select disabled={isLoading} onChange={handleDate2Used} className="select_date">
                  <option value='default'>{lang === "English" ? "-- Select a Date --" : "-- Sélectionnez une date --"}</option>
                  {usedDates !== undefined && usedDates.length !== 0 && usedDates.map((list) => <option key={list} value={list} selected={list === date2Used}>{list}</option>)}
                </select>
              </div>
            </div>

            <ResponsiveContainer width="95%" height={400}>
              <BarChart className="bar" data={usedLimited} margin={{ top: 5, right: 30, left: 30, bottom: 160 }}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis width={20} tick={<CustomizedTick />} dataKey="name" interval={0} label={{ value: lang === "English" ? 'Treatment' : 'Traitement', position: "bottom", offset: 130 }} />
                <YAxis dataKey="occurrences" allowDecimals={false} label={{ value: lang === "English" ? "Times Used" : "Traitements utilisés", position: "insideLeft", angle: -90 }} />
                <Tooltip content={<CustomTooltip3 />} />
                <Bar dataKey="occurrences" fill="#1f417e" maxBarSize={50}>
                  {
                    usedLimited.map((entry, index) => (
                      <Cell fill={colorFill(index, usedElements)} />
                    ))
                  }
                </Bar>
              </BarChart>
            </ResponsiveContainer>
          </div>

        </div>
      </div>
    </>
  )
}

export default TreatmentAvg
