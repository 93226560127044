/**
 * @file
 * Container for the admin panel verify links.
 * Displays a list of all the invalid links
 * @author <mwu078@uottawa.ca>
 */

import React, {useState, useEffect} from 'react'
import Navigation from '../../../components/Admin/Navigation'
import TopBar from '../../../components/Admin/TopBar'
import '../AdminPanel.css'
import {getRequest, postRequest, postFormRequest, patchFormRequest, patchRequest, deleteRequest} from "../../../API/ApiHandler"
import { Button } from '@material-ui/core'

const VerifyLinks = () => {

  const [invalidLinks, setInvalidLinks] = useState([])

  const getInvalidLinks = async () => {
    let data = await getRequest("/learn")
    data = data.data
    if(data) {
      // Only returns the invalid links
      const links = await postRequest('/learn/verify', data)
      setInvalidLinks(links.data);
    } else {
      window.alert("No invalid links found")
    }
  }

  const handleChange = (e, index) => {
    const newInvalidLinks = [...invalidLinks];
    newInvalidLinks[index].link = e.target.value;
    setInvalidLinks(newInvalidLinks);
  }

  const handleSave = async (index) => {
    let newlink = invalidLinks[index]
    await patchRequest("/learn", [newlink])
  }

  return(
    <>
    <div className="admin-container">
      <Navigation/>
      <div className="admin-body">
        <TopBar />
        <table>
            <thead>
              <tr>
                  <th>Treatment ID</th>
                  <th>Treatment name</th>
                  <th>Link</th>
                  <th>Actions</th>
              </tr>
            </thead>

            <tbody>
              {invalidLinks.length > 0 && invalidLinks.map((learn, i) => (
              <tr key={learn.id}>
                  <td>{learn.id}</td>
                  <td>{learn.treatment.name}</td>
                  <td>
                    <input
                      value={learn.link} 
                      size={70} 
                      type='text'
                      onChange={(e) => handleChange(e, i)}
                    />
                  </td>
                  <td>
                    <Button variant="contained" color="primary" onClick={(e) => handleSave(i)}>Save</Button>
                  </td>
              </tr>
              ))}
            </tbody>
        </table>
          <Button variant='contained' color="secondary" onClick={getInvalidLinks}>Click here to validate all the links</Button>
          {invalidLinks.length>0 && 
            <h5>{invalidLinks.length} broken link(s) were found </h5>
          }
      </div>
    </div>
    </>
  )
}

export default VerifyLinks;