import React, {useState} from 'react'
import { withRouter, useHistory  } from 'react-router-dom'
import { useForm } from "react-hook-form";
import * as Yup from 'yup';
import {Formik, Field, Form, ErrorMessage} from 'formik';

import Footer from '../../components/Footer';
import {postRequest} from '../../API/ApiHandler'

import './PasswordRecovery.css';
import TranslateButton from "../../components/UI/TranslateButton";

import { Email } from '@material-ui/icons';

const PasswordRecovery = ({history}) => {
  const { register, handleSubmit, errors} = useForm();
  const [submitted, setSubmission] = useState(false)

  //Get value of language from local storage
  let lang = localStorage.getItem("language")

  const onSubmit = async(values, helpers) => {
    navigateTo('/reset-email-sent');
    const recovery = {
      email: helpers.email,
    }
    let success = await postRequest("/passwordReset/email", recovery)
    setSubmission(true);
    if(success) {
      //TODO: success message
    }
  }

  const initialValues = {
    email: '',
  }

  const validationSchema = Yup.object({
    email: Yup.string().email(lang === "English" ? "Invalid Email ID format" : "Format de l'ID d'email invalide").required(lang === "English" ? "Email ID is required" : "L'adresse électronique est requise"),
  });

  const nextPage = useHistory();
  function navigateTo(path) {nextPage.push(path);}

  return(
    <div className="reset-page">
      <div className="title-section">
        <div>
          <div className="login-translation"> <TranslateButton/> </div>
        </div>
        <h1>JIA Option Map</h1>
      </div>

      <div className="form-section">
        <h2>{lang === "English" ? "Password Recovery" : "Récupération du mot de passe"}</h2>

        <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}   
        onSubmit={handleSubmit(onSubmit)}
        >
          {(formik) => 
          <Form className="form-content">
            <div className="input-container">
            <label><Email style={{fill: "#10434F"}}/>{lang === "English" ? "E-mail" : "Adresse électronique"}</label>
              <Field name="email" type="text" />
              <ErrorMessage name='email' component='p' className='error-message' />
            </div>
            <button type="submit" className="submit-btn" name="submitBtn" disabled={!(formik.dirty && formik.isValid)}>
              {lang === "English" ? "Submit" : "Soumettre"}
            </button>            
          </Form>
          }
        </Formik>
      </div>
      <Footer />
    </div>
  )
}

export default  withRouter(PasswordRecovery)