import React, {useState} from 'react'
import { withRouter } from 'react-router-dom'
import { useForm } from "react-hook-form";
import * as Yup from 'yup';
import {Formik, Field, Form} from 'formik';

import Footer from '../../components/Footer';
import {postRequest} from '../../API/ApiHandler'

import './NewPassword.css';
import { email, valid } from '../../components/PasswordResetService';
import TranslateButton from "../../components/UI/TranslateButton";
import LockIcon from '@material-ui/icons/Lock';
import PasswordChecklist from 'react-password-checklist';

const SetPassword = ({history}) => {
  const { register, handleSubmit, errors} = useForm();
  const [submitted, setSubmission] = useState(false);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [disableSubmit, setDisableSubmit] = useState(true);

  //Get value of language from local storage
  let lang = localStorage.getItem("language")

  const onSubmit = async() => {
    
    const updatedPass = {
      token: valid,
      email: email,
      password: password,
      confirmPassword: confirmPassword
    }
    const success = await postRequest("/passwordReset/new-password", updatedPass);
    console.log(success);

    if(success) {      
      //TODO: success message
      setSubmission(true);
    }
  }

  return(
    <div className="reset-page">
      <div className="title-section">
        <div>
          <div className="login-translation"> <TranslateButton/> </div>
        </div>
        <h1>JIA Option Map</h1>
      </div>

      <div className="form-section">
        <h2>{lang === "English" ? "Set a new password" : "Définir un nouveau mot de passe"}</h2>

        <Formik>
          <Form className="form-content" onSubmit={handleSubmit(onSubmit)}>
            <div className="input-container">
              <label><LockIcon style={{fill: "#10434F"}}/>{lang === "English" ? "New password" : "Nouveau mot de passe"}</label>
              <Field name="password" type="text" onChange={e => setPassword(e.target.value)} />
            </div>

            <div className="input-container">
              <label><LockIcon style={{fill: "#10434F"}}/>{lang === "English" ? "Confirm new password" : "Confirmer le nouveau mot de passe"}</label>
              <Field name="confirmPassword" type="text" onChange={e => setConfirmPassword(e.target.value)} />
            </div><br />

            <PasswordChecklist
              rules={["minLength","specialChar","number","capital","match", "notEmpty"]}
              minLength={8}
              value={password}
              valueAgain={confirmPassword}
              messages={{
                minLength: lang === "English" ? "Password should be at least 8 characters long" : "Le mot de passe doit comporter au moins 8 caractères",
                capital: lang === "English" ? "Password should contain at least one uppercase characters" : "Le mot de passe doit contenir au moins un caractère majuscule",
                number: lang === "English" ? "Password should contain at least one numeric character" : "Le mot de passe doit contenir au moins un caractère numérique",
                specialChar: lang === "English" ? "Password should contain at least one special character" : "Le mot de passe doit contenir au moins un caractère spécial",                  
                match: lang === "English" ? "Passwords should match" : "Les mots de passe doivent correspondre",
                notEmpty: lang === "English" ? "Password fields cannot be empty" : "Les champs du mot de passe ne peuvent pas être vides"
              }}
              onChange={(isValid) => isValid ? setDisableSubmit(false) : setDisableSubmit(true)}
			      />
            {submitted && <span className='successMsg'>
              {lang === "English" 
              ? "Your password has been updated"
              : "Votre mot de passe a été mis à jour"
              }
            </span>}
            <button type="submit" className="submit-btn" name="submitBtn" disabled={disableSubmit}>
              {lang === "English" ? "Submit" : "Soumettre"}
            </button>            
          </Form>
        </Formik>
      </div>
      <Footer />
    </div>
  )
}

export default withRouter(SetPassword)