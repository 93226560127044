import '../../App.css';
import React, { useEffect, useState } from "react";
import Study from "../../components/Review/Study";
import placeholder from "../../img/default_placeholder.png";
import TrafficLight from "../UI/TrafficLight";
import {
  baseUrl,
  getRequest
} from "../../API/ApiHandler";

const ScPDF = ({ passSC }) => {

  const lang = localStorage.getItem("language");
  const [webLinks, setWebLinks] = useState([]);
  const [videoLinks, setVideoLinks] = useState([]);
  const [treatmentIds, setTreatmentIds] = useState([]);

  const trafficBackground = (level) => {
    switch (level) {
      case 1:
        return("#81be33");
       case 2:
        return("#FCB41A");
       case 3:
        return("#ff615d");
       default:
        return("#81be33");
     }
  };


  const getWebLinks = async () => {    
    setWebLinks(await Promise.all(treatmentIds.map(async (id) => {
      let data = await getRequest(`/learn/${id}`);
      return data;
    })))
  };

  const getVideoLinks = async () => {
    setVideoLinks(await Promise.all(treatmentIds.map(async (id) => {
      let data = await getRequest(`/videos/${id}`);
      return data;
    })))
  };

  function getNumOfLinksByLang(lang, i) {
    let numOfLinks = 0;
    webLinks[i]?.map((web) => {
      if (web.language === lang && web.is_app === false) {
        numOfLinks += 1;
      }
      return numOfLinks;
    });
    return numOfLinks;
  }

  function getNumOfLinksByNationAndLang(nation, lang, i) {
    let numOfLinks = 0;
    webLinks[i]?.map((web) => {
      if (
        web.nationality === nation &&
        web.language === lang &&
        web.is_app === false
      ) {
        numOfLinks += 1;
      }
      return numOfLinks;
    });
    return numOfLinks;
  }

  function getNumOfAppByLang(lang, i) {
    let numOfApps = 0;
    webLinks[i]?.map((web) => {
      if (web.is_app === true && web.language === lang) {
        numOfApps += 1;
      }
      return numOfApps;
    });
    return numOfApps;
  }

  function getNumOfVids(lang, i) {
    let numOfVids = 0;
    videoLinks[i]?.map((video) => {
      if (video.language === lang) {
        numOfVids += 1;
      }
      return numOfVids;
    });
    return numOfVids;
  }

  useEffect(() => {
    if (passSC) {
      setTreatmentIds(passSC.map(treatment => {
        return treatment.id;
      }))
    }
   }, [passSC]);

   useEffect(() => {
    getWebLinks();
    getVideoLinks();
   }, [treatmentIds])

  return (
    <>
      {passSC &&
        passSC.map((treatment, i) => (
          <div key={treatment.id}>

              {/* Image and description */}
              <div id="image_and_description">
                <img
                  alt={treatment.name}
                  src={
                    treatment.image
                      ? `${baseUrl}/${treatment.image}`
                      : placeholder
                  }
                />
                <div className="description">
                  <div className="description-title">
                    <h2>
                      {lang === "English" ? treatment.name : treatment.fr_name}
                    </h2>
                  </div>
                  <p>
                    {lang === "English"
                      ? treatment.description
                      : treatment.fr_description}
                  </p>
                </div>
              </div>


              <div id="traffic_and_description" style = {{"backgroundColor":trafficBackground(treatment.traffic_level)}}>
                <div className="traffic-light-container">
                  <TrafficLight level={treatment.traffic_level} />
                </div>
                <div className="traffic-description">
                  <p>
                    {lang === "English"
                      ? treatment.traffic_description
                      : treatment.fr_traffic_description}
                  </p>
                </div>
              </div>

              <div className="treatment_details_overview">
                <h3 id="experts_suggest">
                  {lang === "English"
                    ? "What do experts suggest?"
                    : "Que suggèrent les experts?"}
                </h3>
                <p>
                  {lang === "English"
                    ? treatment.experts_suggest
                    : treatment.fr_experts_suggest}
                </p>
              </div>

              <div className='sc-pdf'>

                {/* Studies section */}
                <div className="research_heading">
                  <h3>
                    {lang === "English"
                      ? "What does the research say?"
                      : "Que dit la recherche?"}
                  </h3>
                </div>

                {treatment.studies && (
                  <div className="treatment_details_study_container" >
                    {treatment.studies.map((study, i) => (
                      <Study key={study.id} study={study} studyNo={i + 1} />
                    ))}
                  </div>
                )}

                <div className="treatment-details-texts">
                  <h3>
                    {lang === "English"
                      ? "How can I use this treatment?"
                      : "Comment puis-je utiliser ce traitement?"}
                  </h3>
                  <p>
                    {lang === "English"
                      ? treatment.how_use
                      : treatment.fr_how_use}
                  </p>

                  <h3>
                    {lang === "English"
                      ? "How soon will I feel better?"
                      : "Quand vais-je me sentir mieux?"}
                  </h3>
                  <p>
                    {lang === "English"
                      ? treatment.how_soon
                      : treatment.fr_how_soon}
                  </p>


                  <h3>
                    {lang === "English"
                      ? "How much does this treatment cost?"
                      : "Combien coûte ce traitement?"}
                  </h3>
                  <p>{lang === "English" ? treatment.cost : treatment.fr_cost}</p>

                  <h3>

                    {lang === "English"
                      ? "Where can I get this treatment?"
                      : "Où puis-je obtenir ce traitement?"}
                  </h3>
                  <p>
                    {lang === "English" ? treatment.where : treatment.fr_where}
                  </p>

                  <h3>
                    {lang === "English"
                      ? "What else should I consider?"
                      : "Que dois-je envisager d'autre?"}
                  </h3>
                  <p>
                    {lang === "English"
                      ? treatment.consider
                      : treatment.fr_consider}
                  </p>

                  <h3>
                    {lang === "English"
                      ? "Learn more:"
                      : "Apprendre encore plus:"}
                  </h3>
                  

                  {getNumOfLinksByLang("English", i) > 0 && lang === "English" ? (
                  <h4>Web pages:</h4>
                ) : (
                  ""
                )}
                {getNumOfLinksByLang("French", i) +
                  getNumOfLinksByLang("English", i) >
                  0 && lang === "French" ? (
                  <h4>Pages web:</h4>
                ) : (
                  ""
                )}


                <div className="treatment_details_links">
                  {getNumOfLinksByNationAndLang("Canadian", "English", i) > 0 &&
                  lang === "English" ? (
                    <h5>Canadian web pages:</h5>
                  ) : (
                    ""
                  )}
                  {getNumOfLinksByNationAndLang("Canadian", "French", i) +
                    getNumOfLinksByNationAndLang("Canadian", "English", i) >
                    0 && lang === "French" ? (
                    <h5>Pages web canadiennes:</h5>
                  ) : (
                    ""
                  )}

                  {webLinks[i]?.map((web, i) => (
                    <div key={web.id}>
                      {lang === "English" &&
                      web.language === "English" &&
                      web.nationality === "Canadian" &&
                      web.is_app === false ? (
                        <p key={i}>
                          <a
                            href={`${web.link}`}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {web.name ? `${web.name}` : `${web.link}`}
                          </a>
                        </p>
                      ) : lang === "French" &&
                        web.nationality === "Canadian" &&
                        web.is_app === false ? (
                        <p key={i}>
                          <a
                            href={`${web.link}`}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {web.name ? `${web.name}` : `${web.link}`}
                          </a>
                        </p>
                      ) : (
                        ""
                      )}
                    </div>
                  ))}

                  {getNumOfLinksByNationAndLang("American", "English", i) > 0 &&
                  lang === "English" ? (
                    <h5>American web pages:</h5>
                  ) : (
                    ""
                  )}
                  {getNumOfLinksByNationAndLang("American", "French", i) +
                    getNumOfLinksByNationAndLang("American", "English", i) >
                    0 && lang === "French" ? (
                    <h5>Pages web américaines:</h5>
                  ) : (
                    ""
                  )}
                  {webLinks[i]?.map((web, i) => (
                    <div key={web.id}>
                      {lang === "English" &&
                      web.language === "English" &&
                      web.nationality === "American" &&
                      web.is_app === false ? (
                        <p key={i}>
                          <a
                            href={`${web.link}`}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {web.name ? `${web.name}` : `${web.link}`}
                          </a>
                        </p>
                      ) : lang === "French" &&
                        web.nationality === "American" &&
                        web.is_app === false ? (
                        <p key={i}>
                          <a
                            href={`${web.link}`}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {web.name ? `${web.name}` : `${web.link}`}
                          </a>
                        </p>
                      ) : (
                        ""
                      )}
                    </div>
                  ))}

                  {getNumOfLinksByNationAndLang("Other", "English", i) > 0 &&
                  lang === "English" ? (
                    <h5>Web pages from other countries:</h5>
                  ) : (
                    ""
                  )}
                  {getNumOfLinksByNationAndLang("Other", "French", i) +
                    getNumOfLinksByNationAndLang("Other", "English", i) >
                    0 && lang === "French" ? (
                    <h5>Pages web d'autres pays:</h5>
                  ) : (
                    ""
                  )}
                  {webLinks[i]?.map((web, i) => (
                    <div key={web.id}>
                      {lang === "English" &&
                      web.language === "English" &&
                      web.nationality === "Other" &&
                      web.is_app === false ? (
                        <p key={i}>
                          <a
                            href={`${web.link}`}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {web.name ? `${web.name}` : `${web.link}`}
                          </a>
                        </p>
                      ) : lang === "French" &&
                        web.nationality === "Other" &&
                        web.is_app === false ? (
                        <p key={i}>
                          <a
                            href={`${web.link}`}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {web.name ? `${web.name}` : `${web.link}`}
                          </a>
                        </p>
                      ) : (
                        ""
                      )}
                    </div>
                  ))}
                </div>

                {getNumOfVids("English", i) > 0 && lang === "English" ? (
                  <h4>Videos:</h4>
                ) : (
                  ""
                )}
                {getNumOfVids("French", i) + getNumOfVids("English", i) > 0 &&
                lang === "French" ? (
                  <h4>Vidéos:</h4>
                ) : (
                  ""
                )}
                <div className="treatment_details_links">
                  {videoLinks[i]?.map((video, i) =>
                    lang === "English" && video.language === "English" ? (
                      <p key={i}>
                        <a
                          href={`${video.link}`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {video.name ? `${video.name}` : `${video.link}`}
                        </a>
                      </p>
                    ) : lang === "French" ? (
                      <p key={i}>
                        <a
                          href={`${video.link}`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {video.name ? `${video.name}` : `${video.link}`}
                        </a>
                      </p>
                    ) : (
                      ""
                    )
                  )}
                </div>

                {getNumOfAppByLang("English", i) > 0 && lang === "English" ? (
                  <h4>Apps:</h4>
                ) : (
                  ""
                )}
                {getNumOfAppByLang("French", i) + getNumOfAppByLang("English", i) >
                  0 && lang === "French" ? (
                  <h4>Applis:</h4>
                ) : (
                  ""
                )}
                {webLinks[i]?.map((web, i) => (
                  <div key={web.id}>
                    {lang === "English" &&
                    web.language === "English" &&
                    web.is_app === true ? (
                      <p key={i}>
                        <a
                          href={`${web.link}`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {web.name ? `${web.name}` : `${web.link}`}
                        </a>
                      </p>
                    ) : lang === "French" && web.is_app === true ? (
                      <p key={i}>
                        <a
                          href={`${web.link}`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {web.name ? `${web.name}` : `${web.link}`}
                        </a>
                      </p>
                    ) : (
                      ""
                    )}
                  </div>
                ))}
                </div>
              </div>

              <p style = {{"pageBreakAfter":"always"}}></p>

          </div>
        ))}
    </>
  )

}

export default ScPDF;