import React, { Component } from "react";
import { Button } from "reactstrap";
import { postRequest } from "../API/ApiHandler";
import "./ContactForm.css";

export default class ContactForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      subject: "",
      message: "",
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  // Function to handle change in form field values.
  handleChange(event) {
    this.setState({
      ...this.state,
      [event.target.name]: event.target.value,
    });
  }

  // Function to handle form submission.
  async handleSubmit(event) {
    event.preventDefault();

    // Store data in variable.
    let contactValues = {
        email: this.state.email,
        subject: this.state.subject,
        message: this.state.message,
    }

    //Toggle Wrapper Modal to close.
    this.props.toggleContact();

    // Make post request.
    let success = await postRequest("/contact", contactValues)

    if (success) {
        // TODO: display success modal.
        alert("Success");
    } else {
      // TODO: display error message.
      alert(
        "We are experiencing issues with our mailer, Please try again later."
      );
    }
  }

  render() {

    //Get value of language from local storage
    let lang = localStorage.getItem("language");
    return (
      <form className="contact-form" onSubmit={this.handleSubmit}>
        <div className="contact-input-container">
          <label className="contact-label">Email:</label>
          <input
            type="email"
            name="email"
            value={this.state.email}
            onChange={this.handleChange}
          />
        </div>
        <div className="contact-input-container">
          <label className="contact-label">Subject:</label>
          <input
            type="text"
            name="subject"
            value={this.state.subject}
            onChange={this.handleChange}
          />
        </div>
        <div className="contact-input-container">
          <label className="contact-label">Message:</label>
          <textarea
            type="input"
            name="message"
            value={this.state.message}
            onChange={this.handleChange}
          />
        </div>
        <Button className="next-btn" type={"submit"} value={"submit"}>
          {lang === "English" ? "Send" : "Send Fr"}
        </Button>
      </form>
    );
  }
}
