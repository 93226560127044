/**
 * @file Config file for JIA Option Map App.
 * @author Manav Patel <mpate136@uottawa.ca>
 */

const config = {
  PUBLIC_PATH: "/app",
};

export default config;
